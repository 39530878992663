import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { HEADER_HEIGHT_LG, HouseCanaryLogoFullColor } from '@hcs/design-system';

import { useUserIsConfirmed } from '../../hooks';
import { useCurrentAppConfig } from '../../hooks/useCurrentAppConfig';
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn';

import styles from './GlobalHeader.module.css';

export interface GlobalHeaderProps {
  /** AppLauncher feature for multi-app bundles */
  appLauncher?: ReactNode;
  /** Content to be rendered in the center of the header */
  children?: ReactNode;
  /** Content to be rendered in the header when logged in */
  authenticatedAdditionalHeaderContent?: ReactNode;
  /** Popover Actions to display when logged in */
  authenticatedActionPopovers?: ReactNode;
  /** Popover Actions to display when not logged in */
  unauthenticatedActionPopovers?: ReactNode;
  /** Fixed position */
  fixed?: boolean;
  className?: string;
}

/** Props that are commonly exposed by the parent of this feature */
export type GlobalHeaderExtendableProps = Omit<
  GlobalHeaderProps,
  'allBundleAppConfigs' | 'appLauncher'
>;

// For fixed header layouts
export const GLOBAL_HEADER_HEIGHT = HEADER_HEIGHT_LG;
const dataHcName = 'global-nav';
export const GlobalHeader = ({
  appLauncher,
  authenticatedAdditionalHeaderContent,
  authenticatedActionPopovers,
  unauthenticatedActionPopovers,
  className,
  fixed = true,
  children,
}: GlobalHeaderProps) => {
  const currentAppConfig = useCurrentAppConfig();
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  const { data: userIsConfirmed, isLoading: userIsConfirmedIsLoading } =
    useUserIsConfirmed();
  return (
    <header
      style={{
        height: `${GLOBAL_HEADER_HEIGHT}px`,
        flex: `0 0 ${GLOBAL_HEADER_HEIGHT}px`,
      }}
      className={classNames(styles.Header, className, {
        [styles.fixed]: fixed,
      })}
      data-hc-name={dataHcName}
    >
      {isLoggedIn && appLauncher}
      <div className={styles.LogoProductNameContainer}>
        <Link
          to="/"
          className={styles.Link}
          data-hc-name={`${dataHcName}-main-link`}
        >
          <HouseCanaryLogoFullColor
            className={styles.HouseCanaryLogoFullColor}
            dataHcName={`${dataHcName}-main-logo`}
          />
        </Link>

        {currentAppConfig.name && (
          <>
            <div className={styles.Divider} />
            {userIsConfirmed || userIsConfirmedIsLoading ? (
              <Link
                className={styles.AppName}
                data-hc-name={`${dataHcName}-product-name`}
                to={currentAppConfig.rootPath}
              >
                {currentAppConfig.globalHeaderTitle || currentAppConfig.name}
              </Link>
            ) : (
              <div
                className={styles.AppName}
                data-hc-name={`${dataHcName}-product-name`}
              >
                {currentAppConfig.globalHeaderTitle || currentAppConfig.name}
              </div>
            )}
          </>
        )}
      </div>
      {children}
      <div data-hc-name={`${dataHcName}-actions`} className={styles.Actions}>
        {isLoggedIn &&
          authenticatedAdditionalHeaderContent &&
          authenticatedAdditionalHeaderContent}
        {isLoggedIn
          ? authenticatedActionPopovers
          : unauthenticatedActionPopovers}
      </div>
    </header>
  );
};
