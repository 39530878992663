import { checkHasCapability } from '../utils/capabilityCheck.utils';

import { useCapabilitiesForUser } from './useCapabilitiesForUser';

export const useCapabilityCheckForUser = (
  userId: number | undefined,
  capabilityKey: string
) => {
  const capabilitiesQuery = useCapabilitiesForUser(userId);
  return {
    ...capabilitiesQuery,
    data: capabilitiesQuery.data?.capabilities
      ? checkHasCapability(capabilityKey, capabilitiesQuery.data.capabilities)
      : undefined,
  };
};
