import React from 'react';

import { Logout } from '@hcs/auth';
import { UnauthenticatedPage } from '@hcs/auth';
import { LayoutGlobalHeaderOutlet } from '@hcs/design-system';
import { VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM } from '@hcs/hc-products';
import { RedirectWithSearchParams } from '@hcs/routing';

import { PrivatePageRedirect } from '../../../auth-redirects/PrivatePageRedirect';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import {
  AcceptInvitationPage,
  ConfirmUserPage,
  ForgotPasswordPage,
  LoginPage,
  ProductSheetPage,
  ResetPasswordPage,
  SamlLoginPage,
} from '../pages';

export const routes = () => [
  {
    element: (
      <LayoutGlobalHeaderOutlet header={<SolutionsPlatformGlobalHeader />} />
    ),
    children: [
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LOGIN,
        element: (
          <UnauthenticatedPage>
            <LoginPage />
          </UnauthenticatedPage>
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.SSO_LOGIN,
        element: (
          <UnauthenticatedPage>
            <SamlLoginPage />
          </UnauthenticatedPage>
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LOGOUT,
        element: (
          <PrivatePageRedirect noRedirectQuery>
            <Logout />
          </PrivatePageRedirect>
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.FORGOT_PASSWORD,
        element: (
          <UnauthenticatedPage>
            <ForgotPasswordPage />
          </UnauthenticatedPage>
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.RESET_PASSWORD,
        element: (
          <UnauthenticatedPage>
            <ResetPasswordPage />
          </UnauthenticatedPage>
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.ACCEPT_INVITATION,
        element: <AcceptInvitationPage />,
      },
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LEGACY_REGISTRATION,
        element: (
          <RedirectWithSearchParams
            path={VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.ACCEPT_INVITATION}
          />
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LEGACY_SIGN_UP,
        element: (
          <RedirectWithSearchParams
            path={VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.ACCEPT_INVITATION}
          />
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LEGACY_JOIN_ORG,
        element: (
          <RedirectWithSearchParams
            path={VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.ACCEPT_INVITATION}
          />
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LEGACY_INVITE,
        element: (
          <RedirectWithSearchParams
            path={VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.ACCEPT_INVITATION}
          />
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.CONFIRM_USER,
        element: <ConfirmUserPage />,
      },
      {
        path: VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.PRODUCT_SHEET,
        element: <ProductSheetPage />,
      },
    ],
  },
];
