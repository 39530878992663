import { useAccount } from '@hcs/auth';
import { useExperienceFlag } from '@hcs/experience-flags';
import { useFeatureFlagsForUser } from '@hcs/huell';
import { CapabilityKeys, FeatureFlags } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useCapabilityCheckForUser } from './useCapabilityCheckForUser';

export const useCapabilityCheck = (capabilityKey: CapabilityKeys) => {
  const legacyAccountQuery = useAccount();
  const capabilitySystemEnabled = useExperienceFlag('CAPABILITY_SYSTEM');
  const capCheckQuery = useCapabilityCheckForUser(
    legacyAccountQuery.data?.user.id,
    capabilityKey
  );
  let data = capCheckQuery.data;
  /**
   * Handle Migration Case:
   * PEXP Appraisal Reports - Feature Flag/Component <> Capability
   */
  const { data: featureFlags } = useFeatureFlagsForUser();
  if (capabilityKey === 'appraisal-compare-report') {
    data =
      featureFlags?.[FeatureFlags.AppraisalComparisonReports] ||
      (capabilitySystemEnabled && capCheckQuery.data);
  }

  /**
   * Handle Migration Case:
   * Offer Now - Feature Flag <> Capability
   */
  if (capabilityKey === 'offer-now') {
    data =
      featureFlags?.[FeatureFlags.OfferNowAvailable] ||
      (capabilitySystemEnabled && capCheckQuery.data);
  }
  /**
   * Migration Case:
   * Effective Date Reports - Feature Flag <> Capability
   */
  if (capabilityKey === 'effective-date-dynamic-report') {
    data =
      featureFlags?.[FeatureFlags.EffectiveDateReports] ||
      (capabilitySystemEnabled && capCheckQuery.data);
  }
  return {
    ...combineUseQueryResult([legacyAccountQuery, capCheckQuery]),
    data,
  };
};
