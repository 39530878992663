export enum VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM {
  LOGIN = '/login',
  LOGOUT = '/logout',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  ACCEPT_INVITATION = '/accept-invitation',
  LEGACY_REGISTRATION = '/registration/email',
  LEGACY_SIGN_UP = '/sign-up',
  LEGACY_JOIN_ORG = '/join-org',
  LEGACY_INVITE = '/invite',
  CONFIRM_USER = '/confirm-user',
  SSO_LOGIN = '/sso/:orgSlug',
  PRODUCT_SHEET = '/product-details/:appSlug',
}

export const CONFIRM_USER_TOKEN_QUERY_PARAM = 'token';
