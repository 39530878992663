import { Capability } from '@hcs/types';

export const checkHasCapability = (
  capabilityKey: string,
  userCapabilities: Capability[]
) => {
  return userCapabilities.some(
    (capability) => capability.key === capabilityKey
  );
};
